
import { Component, Watch } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import { IMakerListRequest, IMaker } from "@/types/maker";
import { IPagination } from "@/types";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // maker
  @Action("maker/userGetList")
  public getList!: (request: IMakerListRequest) => Promise<boolean>;

  @Getter("maker/list")
  public makerList!: IMaker[];

  @Getter("maker/pagination")
  public pagination!: IPagination<IMaker[]>;

  @Mutation("maker/clear")
  public clear!: () => void;

  // watch
  @Watch("$route.query")
  public async changeQuery() {
    this.queryToParams();
    await this.getList(this.params);
  }

  // パラメータ定義
  public params: IMakerListRequest = {
    search: "",
    sort_column: "name",
    sort_direction: "",
    page: 1
  };

  //
  // variables
  //
  public headers = [
    { text: "メーカ名", value: "name", sortable: false },
    { text: "読み仮名", value: "kana", sortable: false },
    { text: "短縮表示名", value: "short_name", sortable: false },
    { text: "発注番号表示名", value: "order_no_name", sortable: false }
  ];

  public sort_direction_list = [
    { value: "", text: "選択なし" },
    { value: "asc", text: "昇順" },
    { value: "desc", text: "降順" }
  ];

  //
  // methods
  //
  private async created() {
    this.clear();
    this.queryToParams();
    await this.getList(this.params);
  }

  // urlからパラメータをセットする
  private queryToParams() {
    const query = this.$route.query;
    this.params = {
      page: query.page ? Number(query.page) : 1,
      search: query.search ? decodeURI(query.search as string) : "",
      sort_column: query.sort_column
        ? decodeURI(query.sort_column as string)
        : "name",
      sort_direction: query.sort_direction
        ? decodeURI(query.sort_direction as string)
        : ""
    };
  }

  // 1ページ目から検索
  // 主に検索条件が変更されたときに利用
  public async searchFirst() {
    this.params.page = 1;
    await this.paramsToQuery();
  }

  // URLのパラメータをセットする
  private paramsToQuery() {
    this.$router
      .push({
        path: this.$router.currentRoute.path,
        query: {
          page: this.params.page ? String(this.params.page) : "1",
          search: this.params.search,
          sort_column: this.params.sort_column,
          sort_direction: this.params.sort_direction
        }
      })
      .catch(() => {});
  }

  // 行をクリックした際の挙動
  public rowClick(maker: IMaker) {
    this.$router.push(`/user/maker/${maker.id}/product`);
  }
}
